#headshot {
    width: 90%;
    height: auto;
    border-radius: 20px;
    min-width: 250px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
}

#about-div {
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
}

.page-view.horizontal > div {
    flex: 1;
    margin: 0 50px 0 50px;
}

@media only screen and (min-width:800px) {
    .page-view.horizontal > div {
        flex: 1;
        margin: 0 100px 0 100px;
        max-height: 100%;
    }

    #headshot {
        height: 100%;
    }
}

#links {
    display: flex;
    justify-content: center;
}

.icon-links {
    height: 50px;
    width: 50px;
    margin: 20px 20px 20px 20px;
}

.page-view.horizontal h1 + p, .page-view.horizontal p {
    font-size: 1.2em;
}

button a:link, button a:hover, button a:visited, button a:active {
    text-decoration: none;
    color: #eee;
    background-color: #3982B3;
}

#resume {
    margin: 15px 0 30px 0;
    width: fit-content;
    padding: 0 25px 0 25px;
}
