@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Anonymous+Pro);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}
.App {
  text-align: center;
  height: 100vh;
}

*, *:before, *:after {
  box-sizing: border-box;
} 
#home {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

@media only screen and (max-width: 799px) {
    #about-me {
        padding-top: 50px;
    }
    #my-skills{
        padding-top: 30px;
    }
    #portfolio-projects {
        padding-top: 50px;
    }
    #blog-posts {
        padding-top: 50px;
    }
    
    #contact-me {
        padding-bottom: 30px;
        padding-top: 60px;
    }
}


@media only screen and (min-width: 800px) {
    #home > div[id] {
        padding-top: 60px;
        background: url(/static/media/background.eb049b91.png) no-repeat center center fixed;
        background-size: cover;
    }
}

.page-view {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    min-height: 100vh;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.vertical {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.horizontal {
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
}

.divBox {
    background-color: white;
    border-radius: 10px;
    padding: 5px
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100vh;
    justify-items: center;
}

@media only screen and (min-width: 800px) {    
    .grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        min-height: 100vh;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding: 0px 120px 0px 120px;
        grid-gap: 40px;
        gap: 40px;
    }
    
    .icon:hover {
        box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);    
    }
}

.icon {
    border-radius: 25px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    padding: 10px;
    background-color: white;
}

@media only screen and (max-width: 799px) {
    .icon {
        max-width: 80px;
        height: auto;
    }
}

.container {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-content: center;
          align-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 20px 0 20px 0;
}

@media only screen and (min-width: 800px) {
  .container {
    display: -webkit-flex;
    display: flex;
    min-height: 100vh;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-content: center;
            align-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 20px 0 20px 0;
  }
  
  .project-card:hover {
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.8);
  }
}

.project-card {
  margin: 10px;
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  width: 300px;
  height: 550px;
}




.card-header img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center;
}

.card-body {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding: 20px;
  min-height: 350px;
}

.card-body p {
    text-align: left;
}

.tag {
  background: #cccccc;
  border-radius: 50px;
  font-size: 12px;
  margin: 3px 3px 3px 3px;
  color: #fff;
  padding: 2px 10px;
  text-transform: uppercase;
  cursor: pointer;
}

.card-body p {
  font-size: 13px;
  margin: 0 0 40px;
}
.user {
  display: -webkit-flex;
  display: flex;
  margin-top: auto;
}

.user img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.user-info h5 {
  margin: 0;
}
.user-info small {
  color: #545d7a;
}

.technology-div{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}
.blog-container {
  min-height: 100vh;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; 
  min-height: -webkit-fit-content; 
  min-height: -moz-fit-content; 
  min-height: fit-content;
  display: grid;
  grid-template-columns: 1fr;
}

@media only screen and (min-width: 800px) {
  .blog-container {
    min-height: 100vh;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; 
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.blog-card {
  height: 300px;
  width: 300px;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  text-decoration: none;
  margin: 10px auto 10px auto; 
  border-radius: 25px;
  background-size: cover;
  background-position: center;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
}


@media only screen and (min-width: 800px) {
  .blog-card {
    height: 400px;
    width: 400px;
    position: relative;
    padding: 20px;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    text-decoration: none;
    margin: 10px 30px 10px 30px; 
    border-radius: 25px;
    background-size: cover;
    background-position: center;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
  }
  
  .blog-card:hover {
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.8);
  }
}

.inner {
  width: 100%;
  height: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; 
  background: rgb(255, 255, 255, 0.85);
  box-sizing: border-box;
  padding: 40px;
  border-radius: 10px;
}

@media only screen and (min-width: 800px) {
    .inner {
        height: 50%;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center; 
        background: rgb(255, 255, 255, 0.85);
        box-sizing: border-box;
        padding: 40px;
        border-radius: 10px;
      }
}

.title {
  font-size: 24px;
  color: black;  
  text-align: center;
  font-weight: 700;
  color: #181818;
  position: relative;
  margin: 0 0 10px 0;
}

@media only screen and (min-width: 800px) {
    .title {
        font-size: 30px;
        color: black;  
        text-align: center;
        font-weight: 700;
        color: #181818;
        position: relative;
        margin: 0 0 20px 0;
      }
}

.subtitle {
  color: #a09c9e;
  text-align: center;
}
/* Add a black background color to the top navigation */
.topnav {
    background-color: #214A66;
    overflow: hidden;
    z-index: 9999;
  }

  .sticky {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
}
  
  /* Style the links inside the navigation bar */
  .topnav a {
    float: left;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
  }
  
  /* Change the color of links on hover */
  .topnav a:hover {
    background-color: #ddd;
    color: black;
  }

  /* Add a color to the active/current link */
  .topnav a.active {
      background-color: #ddd;
      color: black;
  }
  
  
  /* Hamburger menu for mobile */

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }
  
  /* Button styling */
  .menu-toggle {
    display: inline-block;
    padding: .75em 15px;
    line-height: 1em;
    font-size: 1em;
    color: #333;
  }

  /*
   Default styles + Mobile first
   Offscreen menu style
  */
  .main-menu {
    position: absolute;
    display: none;
    left: -200px;
    top: 0;
    height: 100%;
    overflow-y: scroll;
    overflow-x: visible;
    transition: left 0.3s ease,
    box-shadow 0.3s ease;
    z-index: 999;
  }
  
  .main-menu ul {
    list-style: none;
    margin: 0;
    padding: 2.5em 0 0;
    /* Hide shadow w/ -8px while 'closed' */
    box-shadow: -8px 0 8px rgba(0,0,0,.5);
    min-height: 100%;
    width: 200px;
    background: #214A66;
  }
  
  .main-menu a {
    display: block;
    padding: .75em 15px;
    line-height: 1em;
    font-size: 1em;
    color: #fff;
    text-decoration: none;
    border-bottom: 1px solid #fff;
  }
  
  .main-menu li:first-child a {
    border-top: 1px solid #fff;
  }
  
  .main-menu a:hover,
  .main-menu a:focus {
    background: #333;
    text-decoration: underline;
  }
  
  .main-menu .menu-close {
    position: absolute;
    right: 0;
    top: 0;
  }
  
  /*
   On small devices, allow it to toggle...
  */
  /*
   :target for non-JavaScript
   [aria-expanded] will be used if/when JavaScript is added to improve interaction, though it's completely optional.
  */
  .main-menu:target,
  .main-menu[aria-expanded="true"] {
    display: block;
    left: 0;
    outline: none;
    box-shadow: 3px 0 12px rgba(0,0,0,.25);
  }
  
  .main-menu:target .menu-close,
  .main-menu[aria-expanded="true"] .menu-close {
    z-index: 1001;
  }
  
  .main-menu:target ul,
  .main-menu[aria-expanded="true"] ul {
    position: relative;
    z-index: 1000;
  }
  
  /* 
   We could us `.main-menu:target:after`, but
   it wouldn't be clickable.
  */
  .main-menu:target + .backdrop,
  .main-menu[aria-expanded="true"] + .backdrop{
    position: absolute;
    display: block;  
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 998;
    background: #000;
    background: rgba(0,0,0,.85);
    cursor: default;
  }
  
  @supports (position: fixed) {
    .main-menu,
    .main-menu:target + .backdrop,
    .main-menu[aria-expanded="true"] + .backdrop{
      position: fixed;
    }
  }
  
  /*
   Larger screen styling
   Horizontal menu
  */
  @media (min-width: 768px) {
    .menu-toggle,
    .main-menu .menu-close {
      display: none;
    }
    
    /* Undo positioning of off-canvas menu */
    .main-menu {
      position: relative;
          left: auto;
          top: auto;
          height: auto;
      display: block;
    }
    
    .main-menu ul {
      display: -webkit-flex;
      display: flex;
      
      /* Undo off-canvas styling */
      padding: 0;
      box-shadow: none;
      height: auto;
      width: auto;
      background: none;
    }
    
    .main-menu a {
      color: #06c;
      border: 0 !important; /* Remove borders from off-canvas styling */
    }
    
    .main-menu a:hover,
    .main-menu a:focus {
      background: none; /* Remove background from off-canvas styling */
      color: #c00;
    }
  }
  
  /*
   Demo purposes only
  */
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  
  header {
    padding-left: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: baseline;
            align-items: baseline;
    background-color: white;
    z-index: 9999;
  }
  
  article {
    padding: 30px;
    width: 55em;
    font-size: 16px;
    line-height: 1.5em;
  }
  
  article h2 {
    font-weight: 500;
    font-size: 28px;
  }
  
  .logo {
    margin: 0 30px 0 10px;
    font-size: 1.5em;
  }
.page-view.vertical > h1 {
    margin: 30px 10px 30px 10px;
}

.page-view.vertical h2:first-child {
    margin: 30px 10px 30px 10px;
}

#greetings {
  margin-top: 0px;
}

#greetings h1 {
  font-size: 3em;
}

#mobile-headers {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  max-width: 100%;
  margin-top: -140px;
}

#more {
  position:absolute;
  bottom: 25px;
  -webkit-animation: fadeIn 2s backwards 8.5s;
          animation: fadeIn 2s backwards 8.5s;
}

#more a {
  text-decoration: none;
  color: black
}

#more:hover {
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}


.page-view.vertical > h1 {
    margin: 25px 50px 25px 50px;
}

.line-1{
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    position: relative;
    top: 50%;  
    width: 24em;
    margin: 0 auto;
    border-right: 2px solid rgba(255,255,255,.75);
    font-size: 180%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);    
}

/* Animation */
.anim-typewriter{
  -webkit-animation: typewriter 4s steps(44) 0.6s 1 normal both,
              blinkTextCursor 600ms steps(44) 4 normal;
          animation: typewriter 4s steps(44) 0.6s 1 normal both,
              blinkTextCursor 600ms steps(44) 4 normal;
}

.anim-typewriter-two{
  -webkit-animation: typewriter 4s steps(44) 2.8s 1 normal both,
              blinkTextCursor 600ms steps(44) 2.8s 7 normal;
          animation: typewriter 4s steps(44) 2.8s 1 normal both,
              blinkTextCursor 600ms steps(44) 2.8s 7 normal;
}

.anim-typewriter-three{
  -webkit-animation: typewriter 4s steps(44) 2.8s 1 normal both,
              blinkTextCursor 500ms steps(44) 2.8s 3 normal;
          animation: typewriter 4s steps(44) 2.8s 1 normal both,
              blinkTextCursor 500ms steps(44) 2.8s 3 normal;
}

.anim-typewriter-four{
  -webkit-animation: typewriter 4s steps(44) 4.1s 1 normal both,
              blinkTextCursor 500ms steps(44) 4.1s 2 normal;
          animation: typewriter 4s steps(44) 4.1s 1 normal both,
              blinkTextCursor 500ms steps(44) 4.1s 2 normal;
}

.anim-typewriter-five{
  -webkit-animation: typewriter 4s steps(44) 5.0s 1 normal both,
              blinkTextCursor 500ms steps(44) 5.0s 3 normal;
          animation: typewriter 4s steps(44) 5.0s 1 normal both,
              blinkTextCursor 500ms steps(44) 5.0s 3 normal;
}

.anim-typewriter-six{
  -webkit-animation: typewriter 4s steps(44) 6.1s 1 normal both,
              blinkTextCursor 500ms steps(44) 6.1s 3 normal;
          animation: typewriter 4s steps(44) 6.1s 1 normal both,
              blinkTextCursor 500ms steps(44) 6.1s 3 normal;
}

@-webkit-keyframes typewriter{
  from{width: 0;}
  to{width: 24em;}
}

@keyframes typewriter{
  from{width: 0;}
  to{width: 24em;}
}
@-webkit-keyframes blinkTextCursor{
  from{border-right-color: rgba(0, 0, 0, 1);}
  to{border-right-color: transparent;}
}
@keyframes blinkTextCursor{
  from{border-right-color: rgba(0, 0, 0, 1);}
  to{border-right-color: transparent;}
}

/* Google Fonts */

#headshot {
    width: 90%;
    height: auto;
    border-radius: 20px;
    min-width: 250px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
}

#about-div {
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
}

.page-view.horizontal > div {
    -webkit-flex: 1 1;
            flex: 1 1;
    margin: 0 50px 0 50px;
}

@media only screen and (min-width:800px) {
    .page-view.horizontal > div {
        -webkit-flex: 1 1;
                flex: 1 1;
        margin: 0 100px 0 100px;
        max-height: 100%;
    }

    #headshot {
        height: 100%;
    }
}

#links {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.icon-links {
    height: 50px;
    width: 50px;
    margin: 20px 20px 20px 20px;
}

.page-view.horizontal h1 + p, .page-view.horizontal p {
    font-size: 1.2em;
}

button a:link, button a:hover, button a:visited, button a:active {
    text-decoration: none;
    color: #eee;
    background-color: #3982B3;
}

#resume {
    margin: 15px 0 30px 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0 25px 0 25px;
}

 .contact-form {
    background-color: white;
    border-radius: 20px;
    box-sizing: border-box;
    height: auto;
    padding: 20px;
    width: 320px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
  }

  @media only screen and (min-width: 800px) {
    .contact-form {
        background-color: white;
        border-radius: 20px;
        box-sizing: border-box;
        height: auto;
        padding: 20px;
        width: 600px;
        box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
      }
  }
  
  .contact-title {
    color: #000000;
    font-family: sans-serif;
    font-size: 36px;
    font-weight: 600;
    margin-top: 30px;
  }
  
  .contact-subtitle {
    color: #000;
    font-family: sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
  }
  
  .input-container {
    height: 50px;
    position: relative;
    width: 100%;
  }
  
  .ic1 {
    margin-top: 40px;
  }
  
  .ic2 {
    margin-top: 30px;
  }

  .ic3 {
    margin-top: 30px;
    height: 150px;
  }
  
  .contact-input {
    background-color: #fff;
    border-radius: 12px;
    border: 1px solid black;
    box-sizing: border-box;
    color: #000;
    font-size: 18px;
    font-family: inherit;
    height: 100%;
    outline: 0;
    padding: 4px 20px 0;
    width: 100%;
  }
  
  @media only screen and (min-width: 800px) {
    .contact-input {
      background-color: #fff;
      border-radius: 12px;
      border: 0;
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
      box-sizing: border-box;
      color: #000;
      font-size: 18px;
      height: 100%;
      outline: 0;
      padding: 4px 20px 0;
      width: 100%;
    }
  }
  
  .cut {
    background-color: #15172b;
    border-radius: 10px;
    height: 20px;
    left: 20px;
    position: absolute;
    top: -20px;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    transition: -webkit-transform 200ms;
    transition: transform 200ms;
    transition: transform 200ms, -webkit-transform 200ms;
    width: 76px;
  }
  
  .cut-short {
    width: 50px;
  }
  
  .contact-input:not(:-ms-input-placeholder) ~ .cut {
    transform: translateY(8px);
  }
  
  .contact-input:focus ~ .cut,
  .contact-input:not(:placeholder-shown) ~ .cut {
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
  }
  
  .placeholder {
    color: #65657b;
    font-family: sans-serif;
    left: 20px;
    line-height: 14px;
    pointer-events: none;
    position: absolute;
    -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
    transition: color 200ms, -webkit-transform 200ms;
    transition: transform 200ms, color 200ms;
    transition: transform 200ms, color 200ms, -webkit-transform 200ms;
    top: 20px;
  }
  
  .contact-input:not(:-ms-input-placeholder) ~ .placeholder {
    transform: translateY(-30px) translateX(10px) scale(0.75);
  }
  
  .contact-input:focus ~ .placeholder,
  .contact-input:not(:placeholder-shown) ~ .placeholder {
    -webkit-transform: translateY(-30px) translateX(10px) scale(0.75);
            transform: translateY(-30px) translateX(10px) scale(0.75);
  }
  
  .contact-input:not(:-ms-input-placeholder) ~ .placeholder {
    color: #808097;
  }
  
  .contact-input:not(:placeholder-shown) ~ .placeholder {
    color: #808097;
  }
  
  .contact-input:focus ~ .placeholder {
    color: #dc2f55;
  }
  
  .submit {
    background-color: #3982B3;
    border-radius: 12px;
    border: 0;
    box-sizing: border-box;
    color: #eee;
    cursor: pointer;
    font-size: 18px;
    height: 50px;
    margin-top: 38px;
    outline: 0;
    text-align: center;
    width: 100%;
  }
  
  .submit:active {
    background-color: #3982B3;
  }

  .contact-input:focus:invalid {
    box-shadow: 0 2px 20px rgba(69, 62, 62, 0.267) 
  }
