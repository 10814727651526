#home {
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 799px) {
    #about-me {
        padding-top: 50px;
    }
    #my-skills{
        padding-top: 30px;
    }
    #portfolio-projects {
        padding-top: 50px;
    }
    #blog-posts {
        padding-top: 50px;
    }
    
    #contact-me {
        padding-bottom: 30px;
        padding-top: 60px;
    }
}


@media only screen and (min-width: 800px) {
    #home > div[id] {
        padding-top: 60px;
        background: url('../images/background.png') no-repeat center center fixed;
        background-size: cover;
    }
}

.page-view {
    display: flex;
    flex-wrap: wrap;
    min-height: 100vh;
    height: fit-content;
}

.vertical {
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.horizontal {
    justify-content: space-around;
    align-items: center;
}

.divBox {
    background-color: white;
    border-radius: 10px;
    padding: 5px
}
