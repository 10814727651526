 .contact-form {
    background-color: white;
    border-radius: 20px;
    box-sizing: border-box;
    height: auto;
    padding: 20px;
    width: 320px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
  }

  @media only screen and (min-width: 800px) {
    .contact-form {
        background-color: white;
        border-radius: 20px;
        box-sizing: border-box;
        height: auto;
        padding: 20px;
        width: 600px;
        box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
      }
  }
  
  .contact-title {
    color: #000000;
    font-family: sans-serif;
    font-size: 36px;
    font-weight: 600;
    margin-top: 30px;
  }
  
  .contact-subtitle {
    color: #000;
    font-family: sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
  }
  
  .input-container {
    height: 50px;
    position: relative;
    width: 100%;
  }
  
  .ic1 {
    margin-top: 40px;
  }
  
  .ic2 {
    margin-top: 30px;
  }

  .ic3 {
    margin-top: 30px;
    height: 150px;
  }
  
  .contact-input {
    background-color: #fff;
    border-radius: 12px;
    border: 1px solid black;
    box-sizing: border-box;
    color: #000;
    font-size: 18px;
    font-family: inherit;
    height: 100%;
    outline: 0;
    padding: 4px 20px 0;
    width: 100%;
  }
  
  @media only screen and (min-width: 800px) {
    .contact-input {
      background-color: #fff;
      border-radius: 12px;
      border: 0;
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
      box-sizing: border-box;
      color: #000;
      font-size: 18px;
      height: 100%;
      outline: 0;
      padding: 4px 20px 0;
      width: 100%;
    }
  }
  
  .cut {
    background-color: #15172b;
    border-radius: 10px;
    height: 20px;
    left: 20px;
    position: absolute;
    top: -20px;
    transform: translateY(0);
    transition: transform 200ms;
    width: 76px;
  }
  
  .cut-short {
    width: 50px;
  }
  
  .contact-input:focus ~ .cut,
  .contact-input:not(:placeholder-shown) ~ .cut {
    transform: translateY(8px);
  }
  
  .placeholder {
    color: #65657b;
    font-family: sans-serif;
    left: 20px;
    line-height: 14px;
    pointer-events: none;
    position: absolute;
    transform-origin: 0 50%;
    transition: transform 200ms, color 200ms;
    top: 20px;
  }
  
  .contact-input:focus ~ .placeholder,
  .contact-input:not(:placeholder-shown) ~ .placeholder {
    transform: translateY(-30px) translateX(10px) scale(0.75);
  }
  
  .contact-input:not(:placeholder-shown) ~ .placeholder {
    color: #808097;
  }
  
  .contact-input:focus ~ .placeholder {
    color: #dc2f55;
  }
  
  .submit {
    background-color: #3982B3;
    border-radius: 12px;
    border: 0;
    box-sizing: border-box;
    color: #eee;
    cursor: pointer;
    font-size: 18px;
    height: 50px;
    margin-top: 38px;
    outline: 0;
    text-align: center;
    width: 100%;
  }
  
  .submit:active {
    background-color: #3982B3;
  }

  .contact-input:focus:invalid {
    box-shadow: 0 2px 20px rgba(69, 62, 62, 0.267) 
  }