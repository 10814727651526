.page-view.vertical > h1 {
    margin: 30px 10px 30px 10px;
}

.page-view.vertical h2:first-child {
    margin: 30px 10px 30px 10px;
}

#greetings {
  margin-top: 0px;
}

#greetings h1 {
  font-size: 3em;
}

#mobile-headers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin-top: -140px;
}

#more {
  position:absolute;
  bottom: 25px;
  animation: fadeIn 2s backwards 8.5s;
}

#more a {
  text-decoration: none;
  color: black
}

#more:hover {
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}


.page-view.vertical > h1 {
    margin: 25px 50px 25px 50px;
}

.line-1{
    max-width: fit-content;
    position: relative;
    top: 50%;  
    width: 24em;
    margin: 0 auto;
    border-right: 2px solid rgba(255,255,255,.75);
    font-size: 180%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);    
}

/* Animation */
.anim-typewriter{
  animation: typewriter 4s steps(44) 0.6s 1 normal both,
              blinkTextCursor 600ms steps(44) 4 normal;
}

.anim-typewriter-two{
  animation: typewriter 4s steps(44) 2.8s 1 normal both,
              blinkTextCursor 600ms steps(44) 2.8s 7 normal;
}

.anim-typewriter-three{
  animation: typewriter 4s steps(44) 2.8s 1 normal both,
              blinkTextCursor 500ms steps(44) 2.8s 3 normal;
}

.anim-typewriter-four{
  animation: typewriter 4s steps(44) 4.1s 1 normal both,
              blinkTextCursor 500ms steps(44) 4.1s 2 normal;
}

.anim-typewriter-five{
  animation: typewriter 4s steps(44) 5.0s 1 normal both,
              blinkTextCursor 500ms steps(44) 5.0s 3 normal;
}

.anim-typewriter-six{
  animation: typewriter 4s steps(44) 6.1s 1 normal both,
              blinkTextCursor 500ms steps(44) 6.1s 3 normal;
}

@keyframes typewriter{
  from{width: 0;}
  to{width: 24em;}
}
@keyframes blinkTextCursor{
  from{border-right-color: rgba(0, 0, 0, 1);}
  to{border-right-color: transparent;}
}

/* Google Fonts */
@import url(https://fonts.googleapis.com/css?family=Anonymous+Pro);
