.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100vh;
    justify-items: center;
}

@media only screen and (min-width: 800px) {    
    .grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        min-height: 100vh;
        height: fit-content;
        padding: 0px 120px 0px 120px;
        gap: 40px;
    }
    
    .icon:hover {
        box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);    
    }
}

.icon {
    border-radius: 25px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    padding: 10px;
    background-color: white;
}

@media only screen and (max-width: 799px) {
    .icon {
        max-width: 80px;
        height: auto;
    }
}
