/* Add a black background color to the top navigation */
.topnav {
    background-color: #214A66;
    overflow: hidden;
    z-index: 9999;
  }

  .sticky {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
}
  
  /* Style the links inside the navigation bar */
  .topnav a {
    float: left;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
  }
  
  /* Change the color of links on hover */
  .topnav a:hover {
    background-color: #ddd;
    color: black;
  }

  /* Add a color to the active/current link */
  .topnav a.active {
      background-color: #ddd;
      color: black;
  }
  
  
  /* Hamburger menu for mobile */

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }
  
  /* Button styling */
  .menu-toggle {
    display: inline-block;
    padding: .75em 15px;
    line-height: 1em;
    font-size: 1em;
    color: #333;
  }

  /*
   Default styles + Mobile first
   Offscreen menu style
  */
  .main-menu {
    position: absolute;
    display: none;
    left: -200px;
    top: 0;
    height: 100%;
    overflow-y: scroll;
    overflow-x: visible;
    transition: left 0.3s ease,
    box-shadow 0.3s ease;
    z-index: 999;
  }
  
  .main-menu ul {
    list-style: none;
    margin: 0;
    padding: 2.5em 0 0;
    /* Hide shadow w/ -8px while 'closed' */
    -webkit-box-shadow: -8px 0 8px rgba(0,0,0,.5);
       -moz-box-shadow: -8px 0 8px rgba(0,0,0,.5);
            box-shadow: -8px 0 8px rgba(0,0,0,.5);
    min-height: 100%;
    width: 200px;
    background: #214A66;
  }
  
  .main-menu a {
    display: block;
    padding: .75em 15px;
    line-height: 1em;
    font-size: 1em;
    color: #fff;
    text-decoration: none;
    border-bottom: 1px solid #fff;
  }
  
  .main-menu li:first-child a {
    border-top: 1px solid #fff;
  }
  
  .main-menu a:hover,
  .main-menu a:focus {
    background: #333;
    text-decoration: underline;
  }
  
  .main-menu .menu-close {
    position: absolute;
    right: 0;
    top: 0;
  }
  
  /*
   On small devices, allow it to toggle...
  */
  /*
   :target for non-JavaScript
   [aria-expanded] will be used if/when JavaScript is added to improve interaction, though it's completely optional.
  */
  .main-menu:target,
  .main-menu[aria-expanded="true"] {
    display: block;
    left: 0;
    outline: none;
    -moz-box-shadow: 3px 0 12px rgba(0,0,0,.25);
    -webkit-box-shadow: 3px 0 12px rgba(0,0,0,.25);
    box-shadow: 3px 0 12px rgba(0,0,0,.25);
  }
  
  .main-menu:target .menu-close,
  .main-menu[aria-expanded="true"] .menu-close {
    z-index: 1001;
  }
  
  .main-menu:target ul,
  .main-menu[aria-expanded="true"] ul {
    position: relative;
    z-index: 1000;
  }
  
  /* 
   We could us `.main-menu:target:after`, but
   it wouldn't be clickable.
  */
  .main-menu:target + .backdrop,
  .main-menu[aria-expanded="true"] + .backdrop{
    position: absolute;
    display: block;  
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 998;
    background: #000;
    background: rgba(0,0,0,.85);
    cursor: default;
  }
  
  @supports (position: fixed) {
    .main-menu,
    .main-menu:target + .backdrop,
    .main-menu[aria-expanded="true"] + .backdrop{
      position: fixed;
    }
  }
  
  /*
   Larger screen styling
   Horizontal menu
  */
  @media (min-width: 768px) {
    .menu-toggle,
    .main-menu .menu-close {
      display: none;
    }
    
    /* Undo positioning of off-canvas menu */
    .main-menu {
      position: relative;
          left: auto;
          top: auto;
          height: auto;
      display: block;
    }
    
    .main-menu ul {
      display: flex;
      
      /* Undo off-canvas styling */
      padding: 0;
      -webkit-box-shadow: none;
         -moz-box-shadow: none;
              box-shadow: none;
      height: auto;
      width: auto;
      background: none;
    }
    
    .main-menu a {
      color: #06c;
      border: 0 !important; /* Remove borders from off-canvas styling */
    }
    
    .main-menu a:hover,
    .main-menu a:focus {
      background: none; /* Remove background from off-canvas styling */
      color: #c00;
    }
  }
  
  /*
   Demo purposes only
  */
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  
  header {
    padding-left: 20px;
    display: flex;
    align-items: baseline;
    background-color: white;
    z-index: 9999;
  }
  
  article {
    padding: 30px;
    width: 55em;
    font-size: 16px;
    line-height: 1.5em;
  }
  
  article h2 {
    font-weight: 500;
    font-size: 28px;
  }
  
  .logo {
    margin: 0 30px 0 10px;
    font-size: 1.5em;
  }