.blog-container {
  min-height: 100vh;
  height: fit-content; 
  min-height: fit-content;
  display: grid;
  grid-template-columns: 1fr;
}

@media only screen and (min-width: 800px) {
  .blog-container {
    min-height: 100vh;
    height: fit-content; 
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.blog-card {
  height: 300px;
  width: 300px;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  text-decoration: none;
  margin: 10px auto 10px auto; 
  border-radius: 25px;
  background-size: cover;
  background-position: center;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
}


@media only screen and (min-width: 800px) {
  .blog-card {
    height: 400px;
    width: 400px;
    position: relative;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;
    text-decoration: none;
    margin: 10px 30px 10px 30px; 
    border-radius: 25px;
    background-size: cover;
    background-position: center;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
  }
  
  .blog-card:hover {
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.8);
  }
}

.inner {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  background: rgb(255, 255, 255, 0.85);
  box-sizing: border-box;
  padding: 40px;
  border-radius: 10px;
}

@media only screen and (min-width: 800px) {
    .inner {
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; 
        background: rgb(255, 255, 255, 0.85);
        box-sizing: border-box;
        padding: 40px;
        border-radius: 10px;
      }
}

.title {
  font-size: 24px;
  color: black;  
  text-align: center;
  font-weight: 700;
  color: #181818;
  position: relative;
  margin: 0 0 10px 0;
}

@media only screen and (min-width: 800px) {
    .title {
        font-size: 30px;
        color: black;  
        text-align: center;
        font-weight: 700;
        color: #181818;
        position: relative;
        margin: 0 0 20px 0;
      }
}

.subtitle {
  color: #a09c9e;
  text-align: center;
}