@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.container {
  display: flex;
  height: 100%;
  justify-content: space-evenly;
  align-content: center;
  flex-wrap: wrap;
  padding: 20px 0 20px 0;
}

@media only screen and (min-width: 800px) {
  .container {
    display: flex;
    min-height: 100vh;
    height: fit-content;
    justify-content: space-evenly;
    align-content: center;
    flex-wrap: wrap;
    padding: 20px 0 20px 0;
  }
  
  .project-card:hover {
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.8);
  }
}

.project-card {
  margin: 10px;
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  width: 300px;
  height: 550px;
}




.card-header img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  min-height: 350px;
}

.card-body p {
    text-align: left;
}

.tag {
  background: #cccccc;
  border-radius: 50px;
  font-size: 12px;
  margin: 3px 3px 3px 3px;
  color: #fff;
  padding: 2px 10px;
  text-transform: uppercase;
  cursor: pointer;
}

.card-body p {
  font-size: 13px;
  margin: 0 0 40px;
}
.user {
  display: flex;
  margin-top: auto;
}

.user img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.user-info h5 {
  margin: 0;
}
.user-info small {
  color: #545d7a;
}

.technology-div{
    display: flex;
    flex-wrap: wrap;
}